<template>
  <app-card
    title="Ferienwohnung Sabrina"
    :images="images"
    :seasonalPrices="seasonalPrices"
  ></app-card>
</template>

<script>
import appartmentCardVue from '../components/appartment-card.vue'

export default {
  name: 'sabrina',
  data () {
    return {
      images: [
        {
          src: 'app2/Bad.jp2',
          description: 'Badezimmer',
          thumbnail: 'app2/Bad-300.jp2'
        },
        {
          src: 'app2/Balkon.jp2',
          description: 'Balkon',
          thumbnail: 'app2/Balkon-300.jp2'
        },
        {
          src: 'app2/Kueche-1.jp2',
          description: 'Küche',
          thumbnail: 'app2/Kueche-1-300.jp2'
        },
        {
          src: 'app2/Kueche-2.jp2',
          description: 'Küche',
          thumbnail: 'app2/Kueche-2-300.jp2'
        },
        {
          src: 'app2/Schlafzimmer.jp2',
          description: 'Schlafzimmer',
          thumbnail: 'app2/Schlafzimmer-300.jp2'
        },
        {
          src: 'app2/Wohnzimmer-1.jp2',
          description: 'Wohnzimmer',
          thumbnail: 'app2/Wohnzimmer-1-300.jp2'
        },
        {
          src: 'app2/Wohnzimmer-2.jp2',
          description: 'Wohnzimmer',
          thumbnail: 'app2/Wohnzimmer-2-300.jp2'
        }
      ],
      seasonalPrices: [
        {
          start: '2021-04-11',
          end: '2021-05-15',
          saison: 'C',
          price: 90
        },
        {
          start: '2021-05-16',
          end: '2021-07-10',
          saison: 'B',
          price: 110
        },
        {
          start: '2021-07-11',
          end: '2021-08-22',
          saison: 'A',
          price: 120
        },
        {
          start: '2021-08-23',
          end: '2021-10-23',
          saison: 'B',
          price: 110
        },
        {
          start: '2021-10-24',
          end: '2021-12-24',
          saison: 'C',
          price: 90
        },
        {
          start: '2021-12-25',
          end: '2022-01-01',
          saison: 'A',
          price: 120
        },
        {
          start: '2022-01-02',
          end: '2022-04-09',
          saison: 'C',
          price: 90
        },
        {
          start: '2022-04-10',
          end: '2022-04-23',
          saison: 'B',
          price: 110
        },
        {
          start: '2022-04-24',
          end: '2022-05-28',
          saison: 'C',
          price: 90
        },
        {
          start: '2022-05-29',
          end: '2022-07-09',
          saison: 'B',
          price: 110
        },
        {
          start: '2022-07-10',
          end: '2022-09-10',
          saison: 'A',
          price: 130
        },
        {
          start: '2022-09-11',
          end: '2022-10-29',
          saison: 'B',
          price: 110
        },
        {
          start: '2022-10-30',
          end: '2022-12-24',
          saison: 'C',
          price: 90
        },
        {
          start: '2022-12-25',
          end: '2023-01-01',
          saison: 'A',
          price: 130
        },
        {
          start: '2023-01-02',
          end: '2023-04-01',
          saison: 'C',
          price: 100
        },
        {
          start: '2023-04-02',
          end: '2023-04-22',
          saison: 'B',
          price: 120
        },
        {
          start: '2023-04-23',
          end: '2023-05-20',
          saison: 'C',
          price: 100
        },
        {
          start: '2023-05-21',
          end: '2023-07-08',
          saison: 'B',
          price: 120
        },
        {
          start: '2023-07-09',
          end: '2023-09-09',
          saison: 'A',
          price: 140
        },
        {
          start: '2023-09-10',
          end: '2023-10-28',
          saison: 'B',
          price: 120
        },
        {
          start: '2023-10-29',
          end: '2023-12-23',
          saison: 'C',
          price: 100
        },
        {
          start: '2023-12-24',
          end: '2024-01-06',
          saison: 'A',
          price: 140
        }
      ]
    }
  },
  components: {
    'app-card': appartmentCardVue
  },
  mounted () {
    /*
    const req = require.context('../../public/app2', true, /\.(jpg)$/i)
    req.keys().map(img => {
      if (!img.includes('-300')) {
        if (!img.includes('-300')) {
          var imgObj = {
            src: 'app2/' + img,
            description: img,
            thumbnail: 'app2/' + img.replace('.jp2', '') + '-300.jp2'
          }
          this.images.push(imgObj)
        }
      }
    })
    */
  }
}
</script>

<style lang="scss" scoped>
</style>
